import React, { useEffect, useState } from "react";
import PageLayout from "../../components/pageLayout/pageLayout";
import Grid from "@mui/material/Grid";
import Card from "../../components/Card";
import axios from "axios";
import { Box, Container, Modal } from "@mui/material";
import CreateShort from "../../components/Modals/CreateShort";
import TaskCard from "../../components/TaskCard";
import CreativeBriefForm from "../../forms/brief";
import style from "../../styles/modal";
import { format, parseISO } from "date-fns";
import { useHomeData } from "../../components/useHomeData";
import { TailSpin } from "react-loader-spinner";

const plusBoxStyle = {
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  width: "25px",
  borderRadius: "5px",
  textAlign: "center",
  cursor: "pointer",
};
const cardTitle = {
  color: "white",
  fontWeight: "bold",
};
const textNumbers = {
  fontSize: "25px",
  transform: "scale(2)",
  fontWeight: "bold",
};
const Home = () => {
  const {
    inProgressShorts,
    rejectedShorts,
    completedShorts,
    inProgressBrief,
    stalledBriefs,
    completedBrief,
    assignedShorts,
    assignedBriefs,
    ShortProgress,
    BriefsProgress,
    loadingShorts,
    loadingBriefs,
  } = useHomeData();
  const [open, setOpen] = useState(false);
  const [openBriefForm, setOpenBriefForm] = useState(false);

  console.log(assignedShorts);
  return (
    <>
      <CreateShort open={open} setOpen={setOpen} />
      <Modal open={openBriefForm} onClose={() => setOpenBriefForm(false)}>
        <Box
          sx={{
            ...style,
            maxWidth: "600px",
            p: 3,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <CreativeBriefForm
            initialValues={{ type: "Branding", location: "Monroe" }}
            cancel={() => setOpenBriefForm(false)}
            cancelText="Cancel"
          />
        </Box>
      </Modal>
      <PageLayout page="Home">
        <Grid container spacing={2} sx={{ mb: 3, mt: 0, pl: 1.5, pr: 1.5 }}>
          <Grid item xs={12} md={8}>
            <Card>
              <Grid container>
                <Grid
                  container
                  sx={{
                    backgroundColor: "#1976D2",
                    borderRadius: "20px 20px 0 0",
                    display: "flex",
                  }}
                >
                  <Grid item xs={6}>
                    <Container>
                      <p style={cardTitle}>Shorts</p>
                    </Container>
                  </Grid>
                  <Grid item xs={6}>
                    <Container
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                      onClick={() => setOpen(true)}
                    >
                      <p style={plusBoxStyle} onClick={() => setOpen(true)}>
                        +
                      </p>
                    </Container>
                  </Grid>
                </Grid>
                <Container>
                  <Grid container xs={12} sx={{ textAlign: "center" }}>
                    <Grid item xs={4}>
                      <p style={textNumbers}>
                        {loadingShorts ? (
                          inProgressShorts
                        ) : (
                          <TailSpin
                            height="40"
                            width="200"
                            color="#1976D2"
                            ariaLabel="tail-spin-loading"
                            radius="0.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        )}
                      </p>
                      <p>IN PROGRESS</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p style={textNumbers}>
                        {loadingShorts ? (
                          rejectedShorts
                        ) : (
                          <TailSpin
                            height="40"
                            width="200"
                            color="#1976D2"
                            ariaLabel="tail-spin-loading"
                            radius="0.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        )}
                      </p>
                      <p>REJECTED</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p style={textNumbers}>
                        {loadingShorts ? (
                          completedShorts
                        ) : (
                          <TailSpin
                            height="40"
                            width="200"
                            color="#1976D2"
                            ariaLabel="tail-spin-loading"
                            radius="0.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        )}
                      </p>
                      <p>COMPLETED</p>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <Grid container>
                <Grid
                  container
                  sx={{
                    backgroundColor: "#1976D2",
                    borderRadius: "20px 20px 0 0",
                    display: "flex",
                  }}
                >
                  <Grid item xs={6}>
                    <Container>
                      <p style={cardTitle}>Progress</p>
                    </Container>
                  </Grid>
                </Grid>
                <Container>
                  <Grid container xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        justifyContent: "center",
                        height: "127px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p style={textNumbers}>
                        {loadingShorts ? (
                          ShortProgress() + "%"
                        ) : (
                          <TailSpin
                            height="40"
                            width="200"
                            color="#1976D2"
                            ariaLabel="tail-spin-loading"
                            radius="0.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        )}
                      </p>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 9, pl: 1.5, pr: 1.5 }}>
          <Grid item xs={12} md={8}>
            <Card>
              <Grid container>
                <Grid
                  container
                  sx={{
                    backgroundColor: "#BA46C4",
                    borderRadius: "20px 20px 0 0",
                    display: "flex",
                  }}
                >
                  <Grid item xs={6}>
                    <Container>
                      <p style={cardTitle}>Creative briefs</p>
                    </Container>
                  </Grid>
                  <Grid item xs={6}>
                    <Container
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <p
                        style={plusBoxStyle}
                        onClick={() => setOpenBriefForm(true)}
                      >
                        +
                      </p>
                    </Container>
                  </Grid>
                </Grid>
                <Container>
                  <Grid container xs={12} sx={{ textAlign: "center" }}>
                    <Grid item xs={4}>
                      <p style={textNumbers}>
                        {loadingBriefs ? (
                          inProgressBrief
                        ) : (
                          <TailSpin
                            height="40"
                            width="200"
                            color="#BA46C4"
                            ariaLabel="tail-spin-loading"
                            radius="0.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        )}
                      </p>
                      <p>IN PROGRESS</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p style={textNumbers}>
                        {loadingBriefs ? (
                          stalledBriefs
                        ) : (
                          <TailSpin
                            height="40"
                            width="200"
                            color="#BA46C4"
                            ariaLabel="tail-spin-loading"
                            radius="0.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        )}
                      </p>
                      <p>STALLED</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p style={textNumbers}>
                        {loadingBriefs ? (
                          completedBrief
                        ) : (
                          <TailSpin
                            height="40"
                            width="200"
                            color="#BA46C4"
                            ariaLabel="tail-spin-loading"
                            radius="0.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        )}
                      </p>
                      <p>COMPLETED</p>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <Grid container>
                <Grid
                  container
                  sx={{
                    backgroundColor: "#BA46C4",
                    borderRadius: "20px 20px 0 0",
                    display: "flex",
                  }}
                >
                  <Grid item xs={6}>
                    <Container>
                      <p style={cardTitle}>Progress</p>
                    </Container>
                  </Grid>
                </Grid>
                <Container>
                  <Grid container xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        justifyContent: "center",
                        height: "127px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p style={textNumbers}>
                        {loadingBriefs ? (
                          parseInt(BriefsProgress()) + "%"
                        ) : (
                          <TailSpin
                            height="40"
                            width="200"
                            color="#BA46C4"
                            ariaLabel="tail-spin-loading"
                            radius="0.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        )}
                      </p>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container xs={12} sx={{ pl: 1.5 }}>
          <Grid item xs={0.9}>
            <p
              style={{
                borderRadius: "5px",
                backgroundColor: "#D4D4D4",
                textAlign: "center",
                color: "#6D6D6D",
              }}
            >
              TO DO
            </p>
          </Grid>
        </Grid>
        {assignedBriefs.length === 0 && assignedBriefs.length === 0 && (
          <Grid item xs={12} sx={{ pl: 1.5, pr: 1.5 }}>
            <p
              style={{
                borderRadius: "5px",
                backgroundColor: "#D4D4D4",
                textAlign: "center",
                color: "#6D6D6D",
              }}
            >
              0 projects assigned to you
            </p>
          </Grid>
        )}
        {assignedShorts?.map((item) => (
          <TaskCard
            type="short"
            name={item.name}
            details={item.details}
            id={item.id}
            dueDate={item.dueDate}
          />
        ))}
        {assignedBriefs?.map((item) => (
          <TaskCard
            type="brief"
            name={item.name}
            details={item.details}
            id={item.id}
            dueDate={item.dueDate}
          />
        ))}
      </PageLayout>
    </>
  );
};

export default Home;
