import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageLayout from "../../components/pageLayout/pageLayout";
import { Cancel, Edit, Save } from "@mui/icons-material";
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
  Select,
  Modal,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import ButtonBox from "../../components/buttonBox/buttonBox";
import checkToken from "../../utils/checkToken";
import { v4 as uuidv4 } from "uuid";
import { Container } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import RejectModal from "../../components/Modals/RejectModal";
import UnassignModal from "../../components/Modals/UnassignModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "90%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "8px",
};
const commentsStyle = {
  width: "92%",
  height: "200%",
  border: "2px #cccccc solid",
  borderRadius: "10px",
  paddingTop: "20px",
  overflowY: "scroll",
};

const RowComponent = (props) => {
  const { children } = props;

  return (
    <TableRow>
      <TableCell>{children[0]}</TableCell>
      <TableCell>{children[1]}</TableCell>
    </TableRow>
  );
};

function OpenShort() {
  const options = { style: "currency", currency: "USD" };
  const navigate = useNavigate();
  const { id } = useParams();
  const [request, setRequest] = useState([]);
  const [open, setOpen] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [commentary, setCommentary] = useState({});
  const [assignTo, setAssignTo] = useState("");
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [nextCommentId, setNextCommentId] = useState("");
  const [update, setUpdate] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  const [editOn, setEditOn] = useState(false);
  const [localBrief, setLocalBrief] = useState(request);
  const [userEmails, setUserEmails] = useState([
    {
      displayName: "Ralph Helm",
      mail: "ralhel@transblue.org",
    },
    {
      displayName: "Rich Reynolds",
      mail: "ricrey@transblue.org",
    },
    {
      displayName: "Esteban Tobon",
      mail: "tobone@transblue.org",
    },
    {
      displayName: "Uziel Morales",
      mail: "uziel.morales@transblue.org",
    },
  ]);
  const [brief, setBrief] = useState({
    type: "Branding",
  });
  console.log("me abri");
  useEffect(() => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/getbyid?containerId=socialRequests&id=${id}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.comments.length === 0) {
          console.log("no items");
          setNextCommentId(id);
        } else {
          // increment the creative brief ids
          setNextCommentId(
            `MC-${
              parseInt(
                res.data.comments[res.data.comments.length - 1].id.split("-")[1]
              ) + 1
            }`
          );
        }
        console.log(res.data.comments);
        setAssignTo(res.data.assignedTo?.name);
        setRequest(res.data);
      });
  }, [update]);

  function closeRequest() {
    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/update?containerId=socialRequests&id=${request.id}`,
        { archived: true }
      )
      .then((res) => {
        if (res.statusText === "OK") {
          navigate("/projects");
        }
      });
  }
  function deleteRequest() {
    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/delete?containerId=socialRequests&id=${request.id}`
      )
      .then((res) => {
        if (res.statusText === "OK") {
          navigate("/shorts");
        }
      });
  }
  async function stageRequest() {
    const token = await checkToken();
    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/update?containerId=socialRequests&id=${request.id}`,
        { status: "Completed", videoLink: `${videoLink}` }
      )
      .then((res) => {
        if (res.statusText === "OK") {
          try {
            axios
              .post(
                `https://graph.microsoft.com/v1.0/me/sendMail`,
                {
                  message: {
                    subject: "Media Content has been completed",
                    body: {
                      contentType: "HTML",
                      content: `Hello, <br /><br />A media content has been completed by ${localStorage.getItem(
                        "user"
                      )}, here is the link:<br /><br />
                  <a href='https://m3.evergreenbrands.com/shorts/${id}'>https://m3.evergreenbrands.com/shorts/${id}</a> <br /><br />
                  And the video link: <a href={${videoLink}}>${videoLink}</a>
                  `,
                    },
                    toRecipients: [
                      {
                        emailAddress: {
                          address: "kaypet@transblue.org",
                        },
                      },
                    ],
                  },
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                  },
                }
              )
              .then(() => {
                navigate("/shorts");
              })
              .catch((err) => {
                alert(err);
              });
          } catch (e) {
            console.log(e);
          }
          navigate("/shorts");
        }
      });
  }
  async function sendAssignRequest() {
    const token = await checkToken();
    let AssignedEmail = "";
    if (assignTo === "Ralph Helm") AssignedEmail = "ralhel@transblue.org";
    if (assignTo === "Rich Reynolds") AssignedEmail = "ricrey@transblue.org";
    if (assignTo === "Esteban Tobon") AssignedEmail = "tobone@transblue.org";
    console.log(AssignedEmail);
    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/update?containerId=socialRequests&id=${request.id}`,
        { assignedTo: { name: assignTo, email: AssignedEmail } }
      )
      .then((res) => {
        if (res.statusText === "OK") {
          axios
            .post(
              `https://graph.microsoft.com/v1.0/me/sendMail`,
              {
                message: {
                  subject: "Media Content has been assigned to you",
                  body: {
                    contentType: "HTML",
                    content: `Hello, <br /><br />A media content has been assigned to you by ${localStorage.getItem(
                      "user"
                    )}, here is the link:<br /><br />
                  <a href='https://m3.evergreenbrands.com/shorts/${id}'>https://m3.evergreenbrands.com/shorts/${id}</a>
                  `,
                  },
                  toRecipients: [
                    {
                      emailAddress: {
                        address: AssignedEmail,
                      },
                    },
                  ],
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-type": "application/json",
                },
              }
            )
            .then(() => {
              navigate("/shorts");
            })
            .catch((err) => {
              alert(err);
            });
        }
      });
  }
  function shareAcomment() {
    console.log(commentary);
    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/update?containerId=socialRequests&id=${request.id}`,
        {
          ...request,
          comments: [
            ...request.comments,
            {
              id: nextCommentId,
              mail: `${localStorage.getItem("mediaMail")}`,
              user: `${localStorage.getItem("user")}`,
              comment: commentary,
            },
          ],
        }
      )
      .then((res) => {
        setUpdate(uuidv4());
      });
    setRequest({
      ...request,
      comments: [
        ...request.comments,
        {
          id: nextCommentId,
          mail: `${localStorage.getItem("mediaMail")}`,
          user: `${localStorage.getItem("user")}`,
          comment: commentary,
        },
      ],
    });

    // console.log(request)
  }
  const deleteComment = (id) => {
    console.log(id);
    let newList = request.comments.filter((item) => item.id !== id);
    setRequest({ ...request, comments: newList });
    axios.post(
      `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/update?containerId=socialRequests&id=${request.id}`,
      { comments: newList }
    );
  };
  // console.log(request.comments[0].user)
  function handleChange(e) {
    setLocalBrief({
      ...localBrief,
      [e.target.id]: e.target.value,
    });
  }
  function saveUpdates() {
    setBrief(localBrief);

    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/update?containerId=socialRequests&id=${request.id}`,
        localBrief
      )
      .then((res) => {
        setEditOn(false);
      })
      .catch(() => {
        alert("Oops! There was an error saving updates. Please try again");
      });
  }
  return (
    <>
      <UnassignModal
        open={unassignModal}
        setOpen={setUnassignModal}
        style={style}
        id={request.id}
        setAssignTo={setAssignTo}
        setUpdate={setUpdate}
        setId={uuidv4}
        assignedEmail={request.assignedTo?.email}
      />
      <RejectModal
        open={rejectModal}
        setOpen={setRejectModal}
        style={style}
        id={request.id}
        setUpdate={setUpdate}
        setId={uuidv4}
      />

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={{ ...style, maxWidth: "500px", p: 3, position: "relative" }}>
          <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
            Add Video Link
          </Typography>
          <TextField
            value={videoLink || ""}
            fullWidth
            size="small"
            onChange={(e) => {
              setVideoLink(e.target.value);
            }}
            sx={{ my: 2 }}
            label="Video Link"
            id="link"
          />
          <ButtonBox>
            <Button
              sx={{ fontSize: "10px", mb: 2, mt: 3 }}
              color="success"
              variant="contained"
              onClick={() => {
                stageRequest();
                setOpen(false);
              }}
              // disabled={disable}
            >
              Complete & Send
            </Button>
          </ButtonBox>
          <Button
            sx={{
              color: "#000",
              position: "absolute",
              right: "0",
              top: "10px",
            }}
            onClick={() => setOpen(false)}
          >
            X
          </Button>
        </Box>
      </Modal>
      <Modal open={openAssignModal} onClose={() => setOpenAssignModal(false)}>
        <Box sx={{ ...style, maxWidth: "500px", p: 3, position: "relative" }}>
          <Typography
            variant="h6"
            sx={{ mt: 5, mb: 1, mx: 6, textAlign: "center" }}
          >
            Do you want to assign this short to {assignTo} ?
          </Typography>
          <ButtonBox>
            <Button
              sx={{ fontSize: "10px", mb: 2, mt: 3 }}
              color="success"
              variant="contained"
              onClick={() => {
                sendAssignRequest();
                setOpen(false);
              }}
              // disabled={disable}
            >
              Accept
            </Button>
            <Button
              sx={{ fontSize: "10px", mb: 2, mt: 3, ml: 1 }}
              color="error"
              variant="contained"
              onClick={() => {
                setAssignTo("");
                setOpenAssignModal(false);
              }}
              // disabled={disable}
            >
              Cancel
            </Button>
          </ButtonBox>
          <Button
            sx={{
              color: "#000",
              position: "absolute",
              right: "0",
              top: "10px",
            }}
            onClick={() => {
              setAssignTo("");
              setOpenAssignModal(false);
            }}
          >
            X
          </Button>
        </Box>
      </Modal>
      <PageLayout page="Requests">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, mb: 2, position: "relative" }}>
              <Typography variant="h5" sx={{ mb: 3, ml: 2 }}>
                Media Project Details
              </Typography>
              {!editOn && (
                <Button
                  startIcon={<Edit />}
                  onClick={() => setEditOn(true)}
                  sx={{ mt: 1, mb: 1 }}
                >
                  edit details
                </Button>
              )}

              {editOn && (
                <>
                  <Table size="small">
                    <TableRow>
                      <TableCell>
                        <>Name</>
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={localBrief.name || ""}
                          size="small"
                          id="name"
                          onChange={handleChange}
                          label="Brief Name"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <>Details</>
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={localBrief.details || ""}
                          size="small"
                          id="details"
                          onChange={handleChange}
                          label="Details"
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <>Due Date</>
                      </TableCell>
                      <TableCell>
                        <Select
                          size="small"
                          value={localBrief.dueDateType}
                          sx={{ mr: 1, mb: 2 }}
                          onChange={(e) =>
                            setLocalBrief({
                              ...localBrief,
                              dueDateType: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="quarterly">Quarterly</MenuItem>
                          <MenuItem value="specificDay">Specific Day</MenuItem>
                        </Select>
                        {localBrief.dueDateType === "quarterly" && (
                          <Select
                            size="small"
                            value={localBrief.dueDate}
                            onChange={(e) =>
                              setLocalBrief({
                                ...localBrief,
                                dueDate: e.target.value,
                              })
                            }
                          >
                            <MenuItem value="Q1">Q1</MenuItem>
                            <MenuItem value="Q2">Q2</MenuItem>
                            <MenuItem value="Q3">Q3</MenuItem>
                            <MenuItem value="Q4">Q4</MenuItem>
                          </Select>
                        )}
                        {localBrief.dueDateType === "specificDay" && (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Due Date"
                              value={localBrief.dueDate || new Date()}
                              onChange={(newValue) =>
                                setLocalBrief({
                                  ...localBrief,
                                  dueDate: newValue,
                                })
                              }
                              renderInput={(params) => (
                                <TextField fullWidth size="small" {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                  <Button
                    sx={{ mt: 2 }}
                    color="success"
                    startIcon={<Save />}
                    onClick={saveUpdates}
                  >
                    save updates
                  </Button>
                  <Button
                    sx={{ mt: 2 }}
                    color="error"
                    startIcon={<Cancel />}
                    onClick={() => setEditOn(false)}
                  >
                    discard updates
                  </Button>
                </>
              )}
              {!editOn && Object.keys(request).length > 0 && (
                <Table size="small">
                  <TableBody>
                    <RowComponent>
                      <>Name</>
                      {request.name}
                    </RowComponent>
                    <RowComponent>
                      <>Due Date</>
                      {request.dueDate}
                    </RowComponent>
                    <RowComponent>
                      <>Requested Media</>
                      {request.media}
                    </RowComponent>
                    <RowComponent>
                      <>Link Example</>
                      {request.link}
                    </RowComponent>
                    <RowComponent>
                      <>Description</>
                      <>{request.details}</>
                    </RowComponent>
                    <RowComponent>
                      <>Aditional Comments</>
                      <>{request?.additionalComments}</>
                    </RowComponent>
                    <RowComponent>
                      <>Completed Video Link</>
                      <>{request.videoLink || "Not completed yet"}</>
                    </RowComponent>
                  </TableBody>
                </Table>
              )}
              <Grid container sx={{ mt: 3.5, justifyContent: "space-between" }}>
                <Grid item xs={4}>
                  <Typography
                    sx={{ mt: 2, px: 2 }}
                    variant="subtitle2"
                    color="initial"
                  >
                    Who To Assign
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <FormControl
                    size="small"
                    sx={{ mt: 1, ml: 2, width: "212px" }}
                  >
                    <InputLabel id="select-label">Assign To</InputLabel>
                    <Select
                      labelId="select-label"
                      value={assignTo}
                      fullWidth
                      onChange={(e) => {
                        setAssignTo(e.target.value);
                        setOpenAssignModal(true);
                      }}
                      sx={{ mb: 1 }}
                      label="Assign To"
                      id="company"
                    >
                      <MenuItem value={"Ralph Helm"}>Ralph Helm</MenuItem>
                      <MenuItem value={"Rich Reynolds"}>Rich Reynolds</MenuItem>
                      <MenuItem value={"Esteban Tobon"}>Esteban Tobon</MenuItem>
                    </Select>
                    {request.assignedTo?.email ===
                      localStorage.getItem("mediaMail").toLowerCase() && (
                      <Button
                        sx={{ mb: 2 }}
                        color="error"
                        variant="contained"
                        onClick={() => setUnassignModal(true)}
                      >
                        Unassign
                      </Button>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 3.5 }}>
                <Grid
                  item
                  xs={3}
                  sx={{ display: "flex", alignItems: "flex-start" }}
                >
                  <Button
                    sx={{ fontSize: "10px", ml: 2, mt: 3.5 }}
                    variant="contained"
                    color="warning"
                    onClick={() => setRejectModal(true)}
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ButtonBox>
                    <Button
                      sx={{ fontSize: "10px", mr: 1, mt: 3.5 }}
                      variant="contained"
                      color="success"
                      onClick={() => setOpen(true)}
                    >
                      Mark Completed
                    </Button>
                    <Button
                      sx={{ fontSize: "10px", mt: 3.5 }}
                      variant="contained"
                      color="error"
                      onClick={() => deleteRequest()}
                    >
                      Delete
                    </Button>
                  </ButtonBox>
                </Grid>
              </Grid>
              <Button
                sx={{
                  color: "#000",
                  position: "absolute",
                  right: "0",
                  top: "20px",
                }}
                onClick={() => navigate("/shorts")}
              >
                X
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 3,
                mb: 2,
                height: "90vh",
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h5" sx={{ mb: 3, ml: 3 }}>
                Comments
              </Typography>
              <Container sx={commentsStyle}>
                {Object.keys(request).length > 0 &&
                  request?.comments?.map((item, i) => {
                    return (
                      <Container
                        key={i}
                        sx={{
                          position: "relative",
                          mb: 1.5,
                          backgroundColor: "#f6f7f6",
                          borderRadius: "10px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        <Typography fontSize="10px" color="#130CB7">
                          {item.user}
                        </Typography>{" "}
                        <Typography>{item.comment}</Typography>{" "}
                        {item.mail === localStorage.getItem("mediaMail") && (
                          <Typography
                            onClick={() => {
                              deleteComment(item.id);
                            }}
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 10,
                              cursor: "pointer",
                              color: "#dc2f2f",
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </Typography>
                        )}
                      </Container>
                    );
                  })}
              </Container>
              <Container
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    setCommentary(e.target.value);
                  }}
                  sx={{ my: 2 }}
                  label="Share a comment"
                  id="comment"
                />
                <ButtonBox sx={{ justifyContent: "flex-end" }}>
                  <Button
                    sx={{ fontSize: "10px", mt: 1 }}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      shareAcomment();
                    }}
                  >
                    Share a comment
                  </Button>
                </ButtonBox>
              </Container>
              <Button
                sx={{
                  color: "#000",
                  position: "absolute",
                  right: "0",
                  top: "20px",
                }}
                onClick={() => navigate("/shorts")}
              >
                X
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
}

export default OpenShort;
