import { Grid } from "@mui/material";
import React from "react";
import Card from "../Card";
import { useNavigate } from "react-router-dom";
const TaskCard = (props) => {
  const navigate = useNavigate();

  return (
    <Grid container spacing={1} sx={{ mb: 1, mt: 0, pl: 1.5 }}>
      <Grid
        item
        xs={6}
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(`/shorts/${props.id}`)}
      >
        <Card type={props.type}>
          <Grid container spacing={0} sx={{ mb: 1, mt: -1, pl: 1.5 }}>
            <Grid item xs={12} sx={{ mb: -3, mt: -1 }}>
              <p
                style={{ fontWeight: "bold", fontSize: "16px", color: "white" }}
              >
                {props.name}
              </p>
            </Grid>
            <Grid item xs={12} sx={{ mb: -2 }}>
              <p style={{ color: "#B6DAFF", fontSize: "13px" }}>
                {props.details}
              </p>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right", pr: 3 }}>
              <p style={{ color: "#B6DAFF", fontSize: "13px" }}>
                {props.dueDate}
              </p>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TaskCard;
