import React from "react";
import { Button, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import ButtonBox from "../../buttonBox/buttonBox";
import checkToken from "../../../utils/checkToken";

const UnassignModal = ({
  open,
  setOpen,
  style,
  setAssignTo,
  id,
  setUpdate,
  setId,
  assignedEmail,
}) => {
  const [unassignReason, setUnassignReason] = useState("");
  let Adresses = [];
  if (assignedEmail === "tobone@transblue.org") {
    Adresses.push("ralhel@transblue.org", "ricrey@transblue.org");
  } else if (assignedEmail === "ralhel@transblue.org") {
    Adresses.push("tobone@transblue.org", "ricrey@transblue.org");
  } else if (assignedEmail === "ricrey@transblue.org") {
    Adresses.push("tobone@transblue.org", "ralhel@transblue.org");
  }
  async function rejectShort() {
    const token = await checkToken();
    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/update?containerId=socialRequests&id=${id}`,
        { assignedTo: { name: "", email: "" } }
      )
      .then((res) => {
        if (res.statusText === "OK") {
            setUpdate(setId());
            setAssignTo("");
            setOpen(false);
            let sendMultipleEmails = Adresses.map((adress) => {
          axios
            .post(
              `https://graph.microsoft.com/v1.0/me/sendMail`,
              {
                message: {
                  subject: "Rejected short",
                  body: {
                    contentType: "HTML",
                    content: `Hello, <br /><br />A short has been rejected by ${assignedEmail}, here is the link:<br /><br />
                      <a href='https://m3.evergreenbrands.com/shorts/${id}'>https://m3.evergreenbrands.com/shorts/${id}</a><br /><br />

                      Rejected reason (Optional): ${unassignReason}
                      `,
                  },
                  toRecipients: [
                    {
                      emailAddress: {
                        address: Adresses,
                      },
                    },
                  ],
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-type": "application/json",
                },
              }
            )
            .then(() => {})
            .catch((err) => {
              alert(err);
            });
        });
        }
      });
  }
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={{ ...style, maxWidth: "500px", p: 3, position: "relative" }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Unassign Short
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ mt: 5, mb: 1, textAlign: "center" }}
        >
          Add a reason for unassign you this short (optional)
        </Typography>
        <TextField
          value={unassignReason || ""}
          fullWidth
          size="small"
          onChange={(e) => {
            setUnassignReason(e.target.value);
          }}
          sx={{ my: 2 }}
          label="Reasons (optional)"
          id="link"
        />
        <ButtonBox>
          <Button
            sx={{ fontSize: "10px", mb: 2, mt: 3, mr: 1 }}
            color="success"
            variant="contained"
            onClick={() => {
              rejectShort();
            }}
            // disabled={disable}
          >
            Accept
          </Button>
          <Button
            sx={{ fontSize: "10px", mb: 2, mt: 3 }}
            color="error"
            variant="contained"
            onClick={() => setOpen(false)}
            // disabled={disable}
          >
            Cancel
          </Button>
        </ButtonBox>
        <Button
          sx={{
            color: "#000",
            position: "absolute",
            right: "10px",
            top: "20px",
          }}
          onClick={() => setOpen(false)}
        >
          X
        </Button>
      </Box>
    </Modal>
  );
};

export default UnassignModal;
