import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useClearCache } from "react-clear-cache";

import Login from "./pages/login/login";
import Shorts from "./pages/shorts/shorts";
import Calendar from "./pages/calendar/calendar";
import Requests from "./pages/requests/requests";

import { LicenseInfo } from "@mui/x-license-pro";
import OpenRequest from "./pages/openRequest/openRequest";
import Briefs from "./pages/briefs/briefs";
import Home from "./pages/home";
import OpenBrief from "./pages/openBrief/openBrief";
import Reports from "./pages/reports/reports";
import OpenShort from "./pages/openShort/openShort";
import axios from "axios";
import { useState } from "react";

LicenseInfo.setLicenseKey(
  "f94999c7685f2608811fb8b48423f2baTz00ODIyNyxFPTE2OTA3Mzk3NTYzNjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);
  console.log("renderice App");
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/shorts" element={<Shorts />} />
      <Route exact path="/shorts/:id" element={<OpenShort />} />
      <Route exact path="/calendar" element={<Calendar />} />
      <Route exact path="/requests" element={<Requests />} />
      <Route exact path="/requests/:id" element={<OpenRequest />} />
      <Route exact path="/briefs" element={<Briefs />} />
      <Route exact path="/briefs/:id" element={<OpenBrief />} />
      <Route exact path="/reports" element={<Reports />} />
      <Route exact path="/home" element={<Home />} />
    </Routes>
  );
}

export default App;
