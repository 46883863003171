import { Box, Button, Grid, MenuItem, Modal, Paper, Select, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonBox from "../../components/buttonBox/buttonBox";
import PageLayout from "../../components/pageLayout/pageLayout";
import { DataGridPro } from "@mui/x-data-grid-pro";
import style from "../../styles/modal";
import CreativeBriefForm from "../../forms/brief";
import { Delete, Visibility, Warning } from "@mui/icons-material";

function Briefs() {
    const navigate = useNavigate()
    const [briefs, setBriefs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState('Incomplete');
    const [allBriefs, setAllBriefs] = useState([]);
    const [userView, setUserView] = useState('mine');
    const user = localStorage.getItem('user');
    const [columnVisiblityModel, setColumnVisibilityModel] = useState(JSON.parse(localStorage.getItem('columnVisibilityModel')) || {});
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        axios.get('https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/getall?containerId=briefs').then(res => {
            if(res.data !== 'No items found') {
                setBriefs(res.data.filter(brief => !brief.stalled && !brief.completed && brief.assignedTo && brief.assignedTo?.name === user).sort((a, b) => {
                    if(a.created < b.created) return 1
                    else if(b.created < a.created) return -1
                    return 0
                }))
                setAllBriefs(res.data.sort((a, b) => {
                    if(a.created < b.created) return 1
                    else if(b.created < a.created) return -1
                    return 0
                }));
            }
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if(columnVisiblityModel === null || !columnVisiblityModel) {
            let obj = {};
            columns.forEach(col => {
                obj[col.field] = true
            });
            setColumnVisibilityModel(obj)
        }
    }, []);

    const updateColumnVisibility = (model) => {
        console.log(model);
        setColumnVisibilityModel(model);
        localStorage.setItem('columnVisibilityModel', JSON.stringify(model));
    }

    const columns = [
        {field: 'id', headerName: 'ID'},
        {field: 'dueDate', headerName: 'Due Date', renderCell: (params) => (
            (params.row.dueDateType && params.row.dueDateType) === 'specificDay'
                ?   new Date(params.row.dueDate).toLocaleDateString()
                :   params.row.dueDate
        )},
        {field: 'type', headerName: 'Video Type', flex: .5},
        {field: 'name', headerName: 'Brief Name', flex: .75},
        {field: 'location', headerName: 'Location', flex: .5, valueGetter: (params) => params.row.request ? params.row.request?.location : params.row.location},
        {field: 'submitted', headerName: 'Requested', valueGetter: (params) => params.row.submitted ? new Date(params.row.submitted).toLocaleDateString() : 'N/A', flex: .5},
        {field: 'created', headerName: 'Brief Created', renderCell: (params) => new Date(params.row.created).toLocaleDateString(), flex: .5},
        {field: 'assignedTo', headerName: 'Assigned To', flex: .5, valueGetter: (params) => params.row.assignedTo?.name},
        {
            field: 'status', 
            headerName: 'Status', 
            renderCell: (params) => {
                if(params.row.completed) {
                    return <Typography variant='body2' sx={{color: 'green'}}>Complete</Typography>
                }

                else if(params.row.milestones.length > 0) {
                    let incomplete = params.row.milestones.filter(milestone => milestone.percentComplete !== 100);
                    if(incomplete.length === 0) {
                        let index = params.row.milestones?.length - 1;
                        return <Typography variant='body2'>{params.row.milestones[index]?.resource}</Typography>
                    }
                    return <Typography variant='body2'>{incomplete[0].resource}</Typography>
                }

                return <Typography variant='body2'>New Brief</Typography>
            }
        },
        {
            field: 'substatus',
            headerName: 'Sub Status',
            renderCell: (params) => {
                if(params.row.milestones?.length > 0) {
                    let incomplete = params.row.milestones?.filter(milestone => milestone.percentComplete !== 100);
                    if(incomplete.length === 0) {
                        let index = params.row.milestones?.length - 1;
                        return <Typography variant='body2'>{params.row.milestones[index]?.subResource}</Typography>
                    }
                    return <Typography variant='body2'>{incomplete[0]?.subResource}</Typography>
                }

                return ''
            }
        }
    ];

    function redirect(params) {
        navigate(`/briefs/${params.row.id}`)
    };

    function toggleUserView() {
        if(userView === 'mine') {
            setBriefs(allBriefs.filter(brief => !brief.stalled && !brief.archived && !brief.completed));
            setUserView('all');
            setStatus('Incomplete');
        }

        else {
            setBriefs(allBriefs.filter(brief => brief.assignedTo?.name === user && !brief.stalled && !brief.completed));
            setUserView('mine');
            setStatus('Incomplete');
        }
    };

    function handleFilter(newStatus) {
        setStatus(newStatus);
        if(newStatus === 'All') {
            userView === 'mine'
                ? setBriefs(allBriefs.filter(brief => brief.assignedTo && brief.assignedTo?.name === user ))
                : setBriefs(allBriefs)
        }

        else if(newStatus === 'Incomplete') {
            userView === 'mine'
                ? setBriefs(allBriefs.filter(brief => !brief.stalled && !brief.completed && brief.assignedTo && brief.assignedTo?.name === user))
                : setBriefs(allBriefs.filter(brief => !brief.stalled && !brief.completed))
            
        }

        else if(newStatus === 'Complete') {
            userView === 'mine'
                ? setBriefs(allBriefs.filter(brief => brief.completed && brief.assignedTo && brief.assignedTo?.name === user))
                : setBriefs(allBriefs.filter(brief => brief.completed))
            
        }

        else if(newStatus === 'Stalled') {
            userView === 'mine'
                ? setBriefs(allBriefs.filter(brief => brief.stalled && brief.assignedTo && brief.assignedTo?.name === user))
                : setBriefs(allBriefs.filter(brief => brief.stalled))
            
        }
    };

    function deleteSelected() {
        // loop through selected briefs and delete each one, updating state after
        selected.forEach(id => {
            axios.get(`https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/delete?containerId=briefs&id=${id}`).then(() => {
                setBriefs(briefs.filter(brief => brief.id !== id))
            })
        })

        setDeleteModalOpen(false);
    }

    return(
        <>
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
            >
                <Box sx={{...style, maxWidth: '500px', p: 3, maxHeight: '90vh', overflowY: 'auto'}}>
                <Typography variant='h5'>
                        Are you sure you want to delete the selected briefs?
                    </Typography>
                    <Typography sx={{mt: 2, mb: 3, fontWeight: 'bold'}}>
                        <Warning color='warning' sx={{verticalAlign: 'middle'}} /> This action cannot be undone
                    </Typography>

                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                        <Button
                            variant='contained'
                            sx={{fontSize: '10px', mr: 1}}
                            onClick={deleteSelected}
                            color='error'
                        >
                            yes, delete
                        </Button>

                        <Button
                            variant='contained'
                            sx={{fontSize: '10px'}}
                            onClick={() => setDeleteModalOpen(false)}
                            color='success'
                        >
                            no, cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={{...style, maxWidth: '600px', p: 3, maxHeight: '90vh', overflowY: 'auto'}}>
                    <CreativeBriefForm initialValues={{type: 'Branding', location: 'Monroe'}} cancel={() => setOpen(false)} cancelText='Cancel' />
                </Box>
            </Modal>
            <PageLayout page='Creative Briefs'>
                <Grid container>
                    <Grid item xs={6} my='auto'>
                        <Tooltip title={userView === 'mine' ? 'View All Incomplete Briefs' : 'View My Incomplete Briefs'}>
                            <Button
                                onClick={toggleUserView}
                                startIcon={
                                    <Visibility />
                                }
                            >
                                {userView === 'mine'
                                    ? 'view all briefs'
                                    : 'view my briefs'
                                }
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonBox>
                                <Select
                                    value={status}
                                    onChange={(e) => {
                                        handleFilter(e.target.value);
                                    }}
                                    size='small'
                                    sx={{width: '200px'}}
                                >
                                    <MenuItem value='All'>All</MenuItem>
                                    <MenuItem value='Incomplete'>Incomplete</MenuItem>
                                    <MenuItem value='Complete'>Complete</MenuItem>
                                    <MenuItem value='Stalled'>Stalled</MenuItem>
                                </Select>
                                {/* <Button
                                    sx={{fontSize: '12px', mr: 1}}
                                    color='success'
                                    startIcon={<Visibility />}
                                    onClick={viewCompleted}
                                >
                                    view completed
                                </Button> */}
                          
                            {/* {view === 'active' &&
                                <Button
                                    sx={{fontSize: '12px', mr: 1}}
                                    color='error'
                                    startIcon={<Visibility />}
                                    onClick={viewStalled}
                                >
                                    view stalled
                                </Button>
                            }
                            {view === 'stalled' &&
                                <Button
                                    sx={{fontSize: '12px', mr: 1}}
                                    color='success'
                                    startIcon={<TextSnippet />}
                                    onClick={viewActive}
                                >
                                    view active
                                </Button>
                            } */}
                            <Button
                                sx={{fontSize: '12px', bgcolor: '#ed6a22', ml: 3}}
                                variant='contained'
                                onClick={() => setOpen(true)}
                            >
                                create
                            </Button>

                            <Button
                                sx={{fontSize: '12px', ml: 3}}
                                startIcon={
                                    <Delete />
                                }
                                color='error'
                                variant='contained'
                                disabled={selected.length === 0}
                                onClick={() => setDeleteModalOpen(true)}
                            >
                                delete selected
                            </Button>
                        </ButtonBox>
                    </Grid>
                </Grid>

                <Paper sx={{p: 3, mt: 2}}>
                    <DataGridPro
                        loading={loading}
                        disableSelectionOnClick
                        onRowClick={(params) => redirect(params)}
                        rows={briefs}
                        columns={columns}
                        pageSize={50}
                        pagination
                        autoHeight
                        autoPageSize
                        rowsPerPageOptions={[50]}
                        checkboxSelection
                        onSelectionModelChange={(newValue) => setSelected(newValue)}
                        density='compact'
                        onColumnVisibilityModelChange={(model, details) => {
                            updateColumnVisibility(model);
                        }}
                        columnVisibilityModel={columnVisiblityModel}
                    />

                </Paper>
            </PageLayout>
        </>
    )
}

export default Briefs;