import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import checkToken from "../utils/checkToken";
import ButtonBox from "../components/buttonBox/buttonBox";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  Button,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { format } from "date-fns";
import { useId } from "react";

function CreativeBriefForm(props) {
  const [nextBriefId, setNextBriefId] = useState("");
  const navigate = useNavigate();
  const { initialValues, cancel, cancelText, update } = props;
  const [brief, setBrief] = useState({});

  useEffect(() => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/getall?containerId=socialRequests"
      )
      .then((res) => {
        // let fd = res.data[res.data.length - 1].id.split("-")
        // console.log(fd)
        if (res.data === "No items found") {
          // console.log('no items')
          setNextBriefId("MC-300");
        } else {
          // increment the creative brief ids
          setNextBriefId(
            `MC-${parseInt(res.data[res.data.length - 1].id.split("-")[1]) + 1}`
          );
        }
      });
  }, []);
// console.log(nextBriefId)
  // useEffect(() => {
  //     setBrief({
  //         ...brief,
  //         ...initialValues
  //     })
  // }, [initialValues]);

  const [userEmails, setUserEmails] = useState([
    {
      displayName: "Ralph Helm",
      mail: "ralhel@transblue.org",
    },
    {
      displayName: "Rich Reynolds",
      mail: "ricrey@transblue.org",
    },
    {
      displayName: "Esteban Tobon",
      mail: "tobone@transblue.org",
    },
  ]);

  function handleChange(e) {
    setBrief({
      ...brief,
      [e.target.id]: e.target.value,
    });
  }

  async function createBrief() {
    // console.log(nextBriefId);
    const token = await checkToken();
    brief.created = new Date().getTime();

    // if(brief.assignedTo) {
    //     brief.history = [{
    //         action: `Brief Created and assigned to ${brief.assignedTo}`,
    //         date: new Date().getTime(),
    //         user: localStorage.getItem('user')
    //     }];
    // }
    // else {
    //     brief.history = [{
    //         action: `Brief Created by ${localStorage.getItem('user')} - not assigned yet`,
    //         date: new Date().getTime(),
    //         user: localStorage.getItem('user')
    //     }];
    // }

    brief.id = nextBriefId;
    // brief.milestones = [];

    // if(!brief.live) {
    // remove air date if brief is not for a live video
    //     brief.airDate = null;
    // };
    let newObj = {
      ...brief, 
      dueDate: format(brief.dueDate,"MM/dd/yyyy"),
      status:'In progress',
      id: nextBriefId,
      comments: [],
    }
    // console.log(newObj)
    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/save?containerId=socialRequests`,
        newObj
      )
      .then((res) => {
        // console.log(res);
        // console.log('socialRequest');
        if (res.statusText === "OK") {
          console.log(brief.id)
        //   try{
        //     let sendEmailsTo = userEmails.map((user)=>{
        //       axios.post(`https://graph.microsoft.com/v1.0/me/sendMail`, {
        //       "message": {
        //         "subject": "Media Content request created",
        //         "body": {
        //           "contentType": "HTML",
        //           "content": `Hello, <br /><br />A media content has been created by ${localStorage.getItem('user')}, here is the link:<br /><br />
        //           <a href='https://m3.evergreenbrands.com/shorts/${brief.id}'>https://m3.evergreenbrands.com/shorts/${brief.id}</a>
        //           `
        //         },
        //         "toRecipients": [
        //           {
        //             "emailAddress": {
        //               "address": `${user.mail}`
        //             }
        //           }
        //         ],
        //       }
        //     },{
        //       headers: {
        //           Authorization: `Bearer ${token}`,
        //           'Content-type': 'application/json'
        //       }
            
        //   }
        //   ).then(() => {
        //     setBrief({});
            
        //   }).catch(err => {
        //       alert(err)
        //   })
        //     })
              
        // }catch(e){
        //   console.log(e)
        // }
        cancel()
        }
            else {

            }
      });
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Request content
      </Typography>

      <TextField
        value={brief.name || ""}
        size="small"
        id="name"
        onChange={handleChange}
        label="Name"
        sx={{ mb: 3 }}
        fullWidth
      />
      <TextField
        value={brief.media || ""}
        size="small"
        id="media"
        onChange={handleChange}
        label="Media needed"
        sx={{ mb: 3 }}
        fullWidth
      />
      <TextField
        value={brief.details || ""}
        size="small"
        id="details"
        onChange={handleChange}
        label="Details"
        sx={{ mb: 3 }}
        multiline
        minRows={4}
        fullWidth
      />
      <TextField
        value={brief.link || ""}
        size="small"
        id="link"
        onChange={handleChange}
        label="Link"
        sx={{ mb: 3 }}
        fullWidth
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Due Date"
          fullWidth
          
          value={brief.dueDate || null}
          onChange={(newValue) =>
            setBrief({
              ...brief,
              dueDate: newValue,
            })
          }
          renderInput={(params) => (
            <TextField fullWidth size="small" sx={{mb:3}} {...params} />
          )}
        />
      </LocalizationProvider>
      <TextField
        value={brief.additionalComments || ""}
        size="small"
        id="additionalComments"
        onChange={handleChange}
        label="Aditional comments"
        sx={{ mb: 3 }}
        multiline
        minRows={4}
        fullWidth
      />

      {/* 
            {userEmails.length > 0 && 
                <Autocomplete
                    freeSolo
                    renderInput={(params) => <TextField {...params} label='Assigned To' size='small' fullWidth />}
                    options={userEmails}
                    getOptionLabel={option => option.displayName || ''}
                    className='mb-2'
                    onChange={(e, newValue) => {
                        setBrief({
                            ...brief,
                            assignedTo: {
                                name: newValue.displayName,
                                email: newValue.mail
                            }
                        })
                    }}
                    sx={{mb: 3}}
                />
            } */}

      <ButtonBox>
        <Button
          sx={{ fontSize: "10px", mr: 1 }}
          variant="contained"
          color="error"
          onClick={cancel}
        >
          {cancelText}
        </Button>
        <Button
          sx={{ fontSize: "10px" }}
          variant="contained"
          color="success"
          onClick={createBrief}
        >
          save
        </Button>
      </ButtonBox>
    </>
  );
}

export default CreativeBriefForm;
