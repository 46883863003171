import { Container } from '@mui/material'
import React, { useEffect, useState } from 'react'

const Card = ({children, type}) => {
  return (
    <div style={{borderRadius:'20px', boxShadow:'0px 0px 10px #C4C4C4', backgroundColor:`${type == 'short' ? '#1976D2' :  type == 'brief' ? '#BA46C4' : '#fff' }`}}>
        
    {children}
    </div>
  )
}
export default Card