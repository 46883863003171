import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CreativeBriefForm from '../../../forms/newShortForm'
import style from "../../../styles/modal";

const CreateShort = ({open, setOpen}) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            ...style,
            maxWidth: "600px",
            p: 3,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <CreativeBriefForm
            initialValues={{ type: "Branding", location: "Monroe" }}
            cancel={() => setOpen(false)}
            cancelText="Cancel"
          />
        </Box>
      </Modal>
  )
}

export default CreateShort