import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import ButtonBox from "../../components/buttonBox/buttonBox";
import PageLayout from "../../components/pageLayout/pageLayout";
import { TextSnippet, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CreateShort from "../../components/Modals/CreateShort";

function Shorts() {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("active");
  const [briefs, setBriefs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columnVisiblityModel, setColumnVisibilityModel] = useState(
    JSON.parse(localStorage.getItem("columnVisibilityModel")) || {}
  );
  const [unfilteredBriefs, setUnfilteredBriefs] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/getall?containerId=socialRequests"
      )
      .then((res) => {
        if (res.data !== "No items found") {
          setBriefs(res.data);
          setUnfilteredBriefs(res.data);
        }
        setLoading(false);
      });
  }, [open]);
  useEffect(() => {
    if (columnVisiblityModel === null || !columnVisiblityModel) {
      let obj = {};
      columns.forEach((col) => {
        obj[col.field] = true;
      });
      setColumnVisibilityModel(obj);
    }
  }, []);
  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name" },
    {
      field: "date",
      headerName: "Due Date",
      renderCell: (params) =>
        (params.row.dueDateType && params.row.dueDateType) === "specificDay"
          ? new Date(params.row.dueDate).toLocaleDateString()
          : params.row.dueDate,
    },

    { field: "media", headerName: "project", flex: 0.2 },
    { field: "link", headerName: "Link", flex: 0.2 },
    { field: "details", headerName: "Description", flex: 0.75 },
    {
      field: "status",
      headerName: "Status",
      flex: 0.75,
      renderCell: (params) => {
        if (params.row.status === "Completed") {
          return (
            <Typography variant="body2" sx={{ color: "green" }}>
              Completed
            </Typography>
          );
        }
        if (params.row.status === "Rejected") {
          return (
            <Typography variant="body2" sx={{ color: "red" }}>
              Rejected
            </Typography>
          );
        }
      },
    },
  ];
  const updateColumnVisibility = (model) => {
    setColumnVisibilityModel(model);
    localStorage.setItem("columnVisibilityModel", JSON.stringify(model));
  };
  function redirect(params) {
    navigate(`/shorts/${params.row.id}`);
  }
  function viewStalled() {
    setView("stalled");
    setBriefs(unfilteredBriefs.filter((brief) => brief.status === "Completed"));
  }
  function viewActive() {
    setView("active");
    setBriefs(
      unfilteredBriefs.filter((brief) => brief.status === "In progress")
    );
  }
  console.log(unfilteredBriefs);
  function toggleUserView() {
    setBriefs(unfilteredBriefs);
  }
  return (
    <>
      <CreateShort open={open} setOpen={setOpen} />
      <PageLayout page="Shorts">
        <Grid container>
          <Grid item xs={6}>
            <Button onClick={toggleUserView} sx={{ fontSize: "12px" }}>
              View all shorts
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ButtonBox>
              {view === "active" && (
                <Button
                  sx={{ fontSize: "12px", mr: 1 }}
                  color="success"
                  startIcon={<Visibility />}
                  onClick={viewStalled}
                >
                  view completed
                </Button>
              )}
              {view === "stalled" && (
                <Button
                  sx={{ fontSize: "12px", mr: 1 }}
                  startIcon={<TextSnippet />}
                  onClick={viewActive}
                >
                  view in progress
                </Button>
              )}
              <Button
                sx={{ fontSize: "12px", bgcolor: "#ed6a22" }}
                variant="contained"
                onClick={() => setOpen(true)}
              >
                create
              </Button>
            </ButtonBox>
          </Grid>
        </Grid>
        <Paper sx={{ p: 3, mt: 2 }}>
          <DataGridPro
            loading={loading}
            disableSelectionOnClick
            onRowClick={(params) => redirect(params)}
            rows={briefs}
            columns={columns}
            pageSize={50}
            pagination
            autoHeight
            autoPageSize
            rowsPerPageOptions={[50]}
            //checkboxSelection
            density="compact"
            onColumnVisibilityModelChange={(model, details) => {
              updateColumnVisibility(model);
            }}
            columnVisibilityModel={columnVisiblityModel}
          />
        </Paper>
      </PageLayout>
    </>
  );
}

export default Shorts;
