import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

export function useHomeData() {
  const [shorts, setShorts] = useState([]);
  const [briefs, setBriefs] = useState([]);
  const [loadingShorts, setLoadingShorts] = useState(false);
  const [loadingBriefs, setLoadingBriefs] = useState(false);
  useEffect(() => {
    if (shorts.length > 0) setLoadingShorts(true);
  }, [shorts]);
  useEffect(() => {
    if (briefs.length > 0) setLoadingBriefs(true);
  }, [briefs]);

  useEffect(() => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/getall?containerId=socialRequests"
      )
      .then((res) => {
        if (res.data !== "No items found") {
          setShorts(res.data);
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        "https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/getall?containerId=briefs"
      )
      .then((res) => {
        if (res.data !== "No items found") {
          setBriefs(res.data);
        }
      });
  }, []);

  const stalledBriefs = briefs.reduce((acc, el) => {
    if (el.stalled) {
      return acc + 1;
    } else return acc;
  }, 0);
  const completedBrief = briefs.reduce((acc, el) => {
    if (el.status === "Complete") {
      return acc + 1;
    } else return acc;
  }, 0);
  const inProgressBrief = briefs.reduce((acc, el) => {
    if (el.status === undefined) {
      return acc + 1;
    } else return acc;
  }, 0);
  const inProgressShorts = shorts.reduce((acc, el) => {
    if (el.status === "In progress") {
      return acc + 1;
    } else return 0;
  }, 0);

  const rejectedShorts = shorts.reduce((acc, el) => {
    if (el.status === "Rejected") {
      return acc + 1;
    } else return 0;
  }, 0);
  const completedShorts = shorts.reduce((acc, el) => {
    if (el.status === "Completed") {
      return acc + 1;
    } else return 0;
  }, 0);
  const ShortProgress = () => {
    if (completedShorts !== 0) {
      let res = (completedShorts * 100) / shorts.length;
      return res;
    } else return 0;
  };
  const BriefsProgress = () => {
    if (completedBrief !== 0) {
      let res = (completedBrief * 100) / briefs.length;
      return res;
    } else return 0;
  };

  const assignedShorts = shorts.filter(
    (el) =>
      el.assignedTo?.email ===
        localStorage.getItem("mediaMail").toLowerCase() &&
      el?.status === "In progress"
  );

  const assignedBriefs = briefs.filter(
    (el) =>
      el.assignedTo?.email ===
        localStorage.getItem("mediaMail").toLowerCase() &&
      el?.status === "In progress"
  );

  return {
    inProgressShorts,
    rejectedShorts,
    completedShorts,
    inProgressBrief,
    stalledBriefs,
    completedBrief,
    assignedShorts,
    assignedBriefs,
    ShortProgress,
    BriefsProgress,
    loadingShorts,
    loadingBriefs,
  };
}
