import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Article, CalendarMonth, Camera, ListAlt, ShowChart } from '@mui/icons-material';
import logo from '../../images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HomeIcon from "@mui/icons-material/Home";


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PageLayout(props) {
    const navigate = useNavigate();
    const { children, page } = props;
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const links = [
        {
            text: 'Home',
            href: '/home',
            icon: <HomeIcon />
        },
        {
            text: 'Requests',
            href: '/requests',
            icon: <Camera />
        },
        {
            text: 'Creative Briefs',
            href: '/briefs',
            icon: <Article />
        },
        {
            text: 'Shorts',
            href: '/shorts',
            icon: <ListAlt />
        },
        {
            text: 'Calendar',
            href: '/calendar',
            icon: <CalendarMonth />
        },
        {
            text: 'Reports',
            href: '/reports',
            icon: <ShowChart />
        }
    ]

  function logout() {
      localStorage.removeItem('account');
      localStorage.removeItem('access token');
      localStorage.removeItem('user');
      localStorage.removeItem('mediaMail');
      navigate('/');
  }

  useEffect(() => {
    let lastMediaLogin = localStorage.getItem('lastMediaLogin');
    if(!lastMediaLogin || (new Date().getTime() - lastMediaLogin) > 86400000) {
      navigate('/')
    }
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                Project Management
          </Typography>

          <Button 
              color="inherit" 
              onClick={logout} 
              sx={{display: {xs: 'none', md: 'block'}}}
          >
              Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
            <img src={logo} style={{width: '50%', margin: '0 auto'}} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {links.map((link, index) => (
            <ListItem key={link.text} disablePadding sx={page === link.text ? {color: '#ed6a22'}: {}}>
              <ListItemButton
                    component='a'
                    href={link.href}
              >
                <ListItemIcon>
                  {link.icon}
                </ListItemIcon>
                <ListItemText primary={link.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Main open={open} sx={{ flexGrow: 1, p: 1, bgcolor: '#f5f5f5', minHeight: '100vh', borderLeft: '1px solid #e4e5e7' }}>
        <DrawerHeader />
        { children }
      </Main>
    </Box>
  );
}
