import React from "react";
import { Button, MenuItem, Select, Table, TableCell, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import { Cancel, Edit, Save } from "@mui/icons-material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";

const TableRowComp = (props) => {
    const { children } = props;

    return (
        <TableRow >
            <TableCell>{ children[0] }</TableCell>
            <TableCell>{ children[1] }</TableCell>
        </TableRow>
    )
}

function FormDetails(props) {
    const [editOn, setEditOn] = useState(false);
    const { brief, setBrief } = props;
    const [localBrief, setLocalBrief] = useState(props.brief);

    let rows = {
        Name: brief.name,
        'Project Manager': brief.projectManager || '',
        'PM Contact': brief.pmContact || '',
        Type: brief.type,
        Audience: brief.audience,
        Objective: brief.objective,
        Content: brief.content,
        Live: brief.live ? 'Yes' : 'No',
        'Air Date': brief.airDate ? new Date(brief.airDate).toLocaleString() : 'N/A',
        'Due Date':   (brief.dueDateType && brief.dueDateType) === 'specificDay'
        ?   new Date(brief.dueDate).toLocaleDateString()
        :   brief.dueDate
    }
    
    if(brief.request) {
        let { clientInterview, subInterview, description, contactName, location } = brief.request;
        rows = {
            ...rows,
            'Client Interview': clientInterview ? 'Yes' : 'No',
            'Sub Interview': subInterview ? 'Yes' : 'No',
            'Project Description': description,
            'Contact Name': contactName,
            Location: location
        }
    }

    function saveUpdates() {
        setBrief(localBrief);
        
        axios.post(`https://my-tb-cors.herokuapp.com/https://tbmedia-fns.azurewebsites.net/api/update?containerId=briefs&id=${brief.id}`, localBrief).then(res => {
            setEditOn(false);
        }).catch(() => {
            alert('Oops! There was an error saving updates. Please try again')
        })
    }

    function handleChange(e) {
        setLocalBrief({
            ...localBrief,
            [e.target.id]: e.target.value
        })
    }

    return(
        <> 
            {!editOn &&
                <Table size='small'>
                    {Object.keys(rows).map(row => {
                        return (
                            <TableRowComp>
                                <>{row}</>
                                <>{rows[row]}</>
                            </TableRowComp>
                        )
                    })}
                </Table>
            }
            {!editOn &&
                <Button
                    startIcon={<Edit />}
                    onClick={() => setEditOn(true)}
                    sx={{mt: 2}}
                >
                    edit details
                </Button>
            }

            {editOn &&
                <>
                    <Table size='small'>
                        <TableRow>
                            <TableCell>
                                <>Name</>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={localBrief.name || ''}
                                    size='small'
                                    id='name'
                                    onChange={handleChange}
                                    label='Brief Name'
                                    fullWidth
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Project Manager</>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={localBrief.projectManager || ''}
                                    size='small'
                                    id='projectManager'
                                    onChange={handleChange}
                                    label='Project Manager'
                                    fullWidth
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Project Manager Contact</>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={localBrief.pmContact || ''}
                                    size='small'
                                    id='pmContact'
                                    onChange={handleChange}
                                    label='Project Manager Contact'
                                    fullWidth
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Type</>
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={localBrief.type}
                                    onChange={(e) => setLocalBrief({
                                        ...localBrief,
                                        type: e.target.value
                                    })}
                                    fullWidth
                                    size='small'
                                >
                                    <MenuItem value='Branding'>Branding</MenuItem>
                                    <MenuItem value='Construction'>Construction</MenuItem>
                                    <MenuItem value='Dave Wescott'>Dave Wescott</MenuItem>
                                    <MenuItem value='Franchise'>Franchise</MenuItem>
                                    <MenuItem value='Series'>Series</MenuItem>
                                    <MenuItem value='Training'>Training</MenuItem>
                                    <MenuItem value='Transblue Does it Right'>Transblue Does it Right</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Audience</>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={localBrief.audience || ''}
                                    size='small'
                                    id='audience'
                                    onChange={handleChange}
                                    label='Who is the audience?'
                                    fullWidth
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Objective</>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={localBrief.objective || ''}
                                    size='small'
                                    id='objective'
                                    onChange={handleChange}
                                    label='What is the objective?'
                                    fullWidth
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Content</>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={localBrief.content || ''}
                                    size='small'
                                    id='content'
                                    onChange={handleChange}
                                    label='What will you be filming?'
                                    multiline
                                    minRows={4}
                                    fullWidth
                                />                                
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Live</>
                            </TableCell>
                            <TableCell>
                                <Select 
                                    value={localBrief.live}
                                    size='small'
                                    fullWidth
                                    onChange={(e) => setLocalBrief({
                                        ...localBrief,
                                        live: e.target.value
                                    })}
                                >
                                    <MenuItem value={false}>No</MenuItem>
                                    <MenuItem value={true}>Yes</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Air Date</>
                            </TableCell>
                            <TableCell>
                                {!localBrief.live
                                    ?   <>N/A</>
                                    :   <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                label="Date&Time picker"
                                                value={localBrief.airDate}
                                                onChange={(newValue) => setLocalBrief({
                                                    ...localBrief,
                                                    airDate: newValue
                                                })}
                                                renderInput={(params) => <TextField fullWidth size='small' sx={{mt: 3}} {...params} />}
                                            />
                                        </LocalizationProvider>
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <>Due Date</>
                            </TableCell>
                            <TableCell>
                                <Select
                                    size='small'
                                    value={localBrief.dueDateType}
                                    sx={{mr: 1}}
                                    onChange={(e) => setLocalBrief({
                                        ...localBrief,
                                        dueDateType: e.target.value
                                    })}
                                >
                                    <MenuItem value='quarterly'>Quarterly</MenuItem>
                                    <MenuItem value='specificDay'>Specific Day</MenuItem>
                                </Select>
                                {localBrief.dueDateType === 'quarterly' &&
                                    <Select
                                        size='small'
                                        value={localBrief.dueDate}
                                        onChange={(e) => setLocalBrief({
                                            ...localBrief,
                                            dueDate: e.target.value
                                        })}
                                    >
                                        <MenuItem value='Q1'>Q1</MenuItem>
                                        <MenuItem value='Q2'>Q2</MenuItem>
                                        <MenuItem value='Q3'>Q3</MenuItem>
                                        <MenuItem value='Q4'>Q4</MenuItem>
                                    </Select>
                                }
                                {localBrief.dueDateType === 'specificDay' &&
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Due Date"
                                            value={localBrief.dueDate || new Date()}
                                            onChange={(newValue) => setLocalBrief({
                                                ...localBrief,
                                                dueDate: newValue
                                            })}
                                            renderInput={(params) => <TextField fullWidth size='small' {...params} />}
                                        />
                                    </LocalizationProvider>
                                }
                            </TableCell>
                        </TableRow>
                       
                    </Table>
                    <Button
                        sx={{mt: 2}}
                        color='success'
                        startIcon={<Save />}
                        onClick={saveUpdates}
                    >
                        save updates
                    </Button>
                    <Button
                        sx={{mt: 2}}
                        color='error'
                        startIcon={<Cancel />}
                        onClick={() => setEditOn(false)}
                    >
                        discard updates
                    </Button>
                </>
            }
        </>
    )
}

export default FormDetails;